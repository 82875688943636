// Sign in page for App

import { useState } from "react";
import useLoginUser from '../../hooks/admin/auth/uselogin';
import { useNavigate } from "react-router-dom";
import _route from "../../constants/routes";
import Spinnar from "../../components/spinnar";
import logo from '../../assets/images/full-logo.png'
import logoDark from '../../assets/images/logo-dark.png'
import bga1 from '../../assets/images/bga1.png'
import './auth.css'

export default function SignIn() {
    const {loginUser, loading} = useLoginUser()
	const navigate = useNavigate()
	const [showPassword, setShowPassword] = useState(false)
	const [formData, setFormData] = useState({
		username: '',
		password: ''
	})

	const handleSubmit = async (e)=>{
		e.preventDefault()
		await loginUser(formData) && navigate(_route._admin_dashboard)
	}

	return (
		
		<div className="nk-app-root">
			{/* main @s */}
			<div className="nk-main ">
				{/* wrap @s */}
				<div className="nk-wrap nk-wrap-nosidebar">
					{/* content @s */}
					<div className="nk-content ">
						<div className="nk-split nk-split-page nk-split-md">
							<div className="nk-split-content nk-split-stretch d-none d-md-flex justify-center align-center flex-column">
								<div className="side-login">
									<img src={logo} style={{ maxWidth: '85%' }} alt="bga" />
									<h3 className="fw-bold text-center mt-5">
										It’s not about what make. It’s about what you make possible.
									</h3>
									<p>Welcome to Big Gorilla Apps!</p>
								</div>
							</div>

							<div className="nk-split-content nk-block-area nk-block-area-column nk-auth-container">
								<div className="nk-block ms-0 nk-block-middle nk-auth-body">
																		
									<div className="nk-block-head auth-img">
										<img src={bga1} alt="bga" />
									</div>
									{/* .nk-block-head */}
									<form onSubmit={handleSubmit}>
										{/* .form-group */}
										<div className="form-group">
											<div>
												<div className="form-label-group">
													<label className="form-label" htmlFor="default-01">Email</label>
												</div>
												<div className="form-control-wrap">
													<input type="email" className="form-control p-4 bga-input form-control-lg" id="default-01" name="username" value={formData.username} onChange={(e) => setFormData(prv => ({...prv, username: e.target.value}))} placeholder="Enter your email address or username" required={true} />
												</div>
											</div>
										</div>

										{/* .form-group */}
										<div className="form-group">
											<div className="form-label-group">
												<label className="form-label" htmlFor="password">Passcode</label>
												{/* <a className="link link-primary link-sm" tabIndex={-1} href="html/pages/auths/auth-reset-v3.html">Forgot Code?</a> */}
											</div>
											<div className="form-control-wrap">
												<span onClick={() => setShowPassword(prv => !prv)} className="form-icon form-icon-right input-icon lg" >
													<em className={`passcode-icon icon-show icon ni ${showPassword ? 'ni-eye-off' : 'ni-eye '}`} />
												</span>
												<input type={showPassword ? "text" : "password"} className="form-control p-4 bga-input form-control-lg" id="password" name="password" value={formData.password} onChange={(e) => setFormData(prv => ({...prv, password: e.target.value}))}  placeholder="***********" required={true} />
												<p className="mb-0 text-white text-right mt-2 cursor-pointer">Forget password?</p>
											</div>
										</div>
										{/* .form-group */}
										<div className="form-group flex-center mt-4">
											<button className="btn btn-lg btn-primary btn-block py-4 btn-bga" type="submit">
												{
													loading ? <Spinnar /> : "Sign in"
												}
											</button>
										</div>
									</form>
									{/* form */}
								</div>
								{/* .nk-block */}
							</div>
							{/* .nk-split-content */}
						</div>
						{/* .nk-split */}
					</div>
					{/* wrap @e */}
				</div>
				{/* content @e */}
			</div>
			{/* main @e */}
		</div>
	);
}