// Dashboard for Admin
import { useState } from "react";
import ProfileDetails from '../components/admin/profiledetails'
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useGetSingleAdmin from '../hooks/admin/admin/usegetsingleadmin';

export default function ViewAdmin() {
	const navigate = useNavigate()
    const {id} = useParams()
	const [tabIndex, setTabIndex] = useState(0)
    const {getSingleAdmin, /*loading*/} = useGetSingleAdmin()
    const [data, setData] = useState()
    const [clientData, setClientData] = useState(null)

  
    
  const fetch = async ()=>{
	// setProfileModal(false)
	const data = await getSingleAdmin(id)
	if(data !== undefined){
	  setClientData({
		firstName: data.fullNames.firstname,
		lastName: data.fullNames.lastname,
		phone: data.phone
	  })
	  console.log(data)
	  setData(data)
	}
  }


  useEffect(()=>{  
	fetch()
	// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  
	return (
    <div className="nk-content-body">
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between g-3">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">
              Administrator /{" "}
              <strong className="text-primary small">{`${data?.fullNames?.firstname} ${data?.fullNames?.lastname}`}</strong>
            </h3>
            {/* <div className="nk-block-des text-soft">
              <ul className="list-inline">
                <li>
                  User ID: <span className="text-base">UD003054</span>
                </li>
                <li>
                  Last Login:{" "}
                  <span className="text-base">15 Feb, 2019 01:02 PM</span>
                </li>
              </ul>
            </div> */}
          </div>
          <div className="nk-block-head-content">
            <div
			onClick={()=> navigate(-1)}
              className="btn btn-outline-light bg-white d-none d-sm-inline-flex"
            >
              <em className="icon ni ni-arrow-left" />
              <span>Back</span>
            </div>
            <span
			onClick={()=> navigate(-1)}
              className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
            >
              <em className="icon ni ni-arrow-left" />
            </span>
          </div>
        </div>
      </div>
      {/* .nk-block-head */}
      <div className="nk-block">
        <div className="card">
          <div className="card-aside-wrap">
            <div className="card-content w-100">
              <ul className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
                <li onClick={()=> setTabIndex(0)}  className={`px-2 px-md-3 clipboard-init ${tabIndex === 0 && ' nav-item active' }`}>
                  <div className={`nav-link`}>
                    <em className="icon ni ni-user-circle" />
                    <span>Personal Info</span>
                  </div>
                </li>
                {/* <li onClick={()=> setTabIndex(1)}  className={`px-2 px-md-3 clipboard-init ${tabIndex === 1 && ' nav-item active' }`}>
                  <div className={`nav-link `}>
                    <em className="icon ni ni-repeat" />
                    <span>Widget</span>
                  </div>
                </li>
                <li onClick={()=> setTabIndex(2)}  className={`px-2 px-md-3 clipboard-init ${tabIndex === 2 && ' nav-item active' }`}>
                  <div className={`nav-link `}>
                    <em className="icon ni ni-file-text" />
                    <span>Grouped Menu</span>
                  </div>
                </li>
                <li onClick={()=> setTabIndex(3)}  className={`px-2 px-md-3 clipboard-init ${tabIndex === 3 && ' nav-item active' }`}>
                  <div className={`nav-link `}>
                    <em className="icon ni ni-file-text" />
                    <span>Menu</span>
                  </div>
                </li> */}
              </ul>
              {/* .nav-tabs */}

              {
				tabIndex === 0 && <ProfileDetails fetch={fetch} data={data} upDateProfile={clientData} setUpDateProfile={setClientData} />
			  }

              {/* .card-inner */}
            </div>
            {/* .card-aside */}
          </div>
          {/* .card-aside-wrap */}
        </div>
        {/* .card */}
      </div>
      {/* .nk-block */}
    </div>
  );
}